import React from "react";
import { H1, BoxContainer } from "./BaseContentStyled";
import styled from "styled-components";
import { Helmet } from "react-helmet";

const p = styled.p`
  margin-bottom: 26px;
`;

export default function WhatWeOffer() {
  return (
    <div>
      <Helmet>
        <title>What We Offer</title>
      </Helmet>

      <div style={{ backgroundColor: "white" }}>
        <BoxContainer>
          <H1>What We Offer</H1>
          <p>
            <strong>
              Jangipur Mega Food Park occupies an expanse of nearly 90 Acres in
              the First phase with potential to expand upto 250 Acres in 2
              <sup>nd</sup>&nbsp;and 3<sup>rd</sup>&nbsp;phases.
            </strong>
          </p>
          <p>
            <strong>A. &nbsp;Infrastructure</strong>
          </p>
          <p>
            <strong>i) Road</strong>: Wide well paved, illuminated roads with
            drainage
            <br />
            Main Road- 27 mtr wide
            <br />
            Arterial roads-21 mtr wide
            <br />
            Internal Roads- 18 mtr wide
            <br />
            <strong>
              <br />
              ii) Power:&nbsp;
            </strong>
            33 /11 KV HT Power substation with 3 Internal LT Substations
          </p>

          <p>
            <strong>iii) Water:&nbsp;</strong>Ample availability of treated
            water with storage capacity of 1million litres.
            <br />
            <strong>
              <br />
              iv) ETP/STP:
            </strong>
            &nbsp;Three(3) units for ETP and STP are installed with following
            capacity:
            <br />
            Effluent Treatment Plant for Industrial effluent: 560 KLD
            <br />
            Sewerage Treatment Plant of 35 KLD
            <br />
            ETP of Rice Mill Effluent: 150 KLD
          </p>
          <p>
            <strong>Features of ETP and STP are:</strong>
          </p>
          <ul>
            <li>Solid and liquid effluent collection</li>
            <li>Common treatment plant</li>
            <li>Solid Waste disposal facility</li>
            <li>Bio conversion of organic wastes</li>
          </ul>
          <p>Vegetables:5.09 Million MT</p>
          <p>Fruits: 1.42 Million MT</p>
          <p>
            <strong>B. &nbsp;OPPORTUNITIES for MANUFACTURING UNITS</strong>
          </p>
          <p>The following are available for leasing:</p>
          <p>
            <strong>i) &nbsp;Land:</strong>&nbsp;Developed plots of land of size
            Half Acre to 2 Acres are available. All plots are connected with
            internal roads and are offered Water and Electricity connectivity as
            per requirement subject to approval by respective authorities. A
            total of 17 plots are available.
          </p>
          <p>
            <strong>ii) Factory Sheds</strong>: These are Ready to Use sheds
            operating on Plug and Play mechanism. The &nbsp;highlights are as
            follows:
          </p>
          <p>
            <strong>No. of Units:&nbsp;</strong>5 arranged in a row.
            <br />
            <strong>Space:&nbsp;1800</strong>&nbsp;sqmtr with 300 sqmtr
            mezzanine area.
            <br />
            <strong>Dimensions&nbsp;</strong>of each shed: : 40 mtr (lth) 37.5
            mtr (w) with a clear height of 7 mtr
            <br />
            <strong>Additional space:</strong>&nbsp;Open space is available for
            use on all sides of each shed. Dimensions of this space on all sides
            are 8.50mt(West), 5,24 mt (North), 6.5 mt (South) and 7 mt (East)
            <br />
            <strong>Unloading Bay:&nbsp;</strong>attached with each shed
            <br />
            <strong>Water Supply :</strong>&nbsp;Available from PHE storage
            which can be used as drinking water too
            <br />
            <strong>Natural light</strong>: Sheds are equipped with windows to
            let in ample air and sunlight
            <br />
            <strong>Power:</strong>&nbsp;Uninterupted Power supply
            <br />
            <strong>Utility service:</strong>&nbsp;Separate Toilet blocks for
            Male and Female readily available inside each shed.
            <br />
            <strong>Protection:</strong>&nbsp;Doors of each shed protected with
            Fire retardant material that gives protection for 2 hours.
            <br />
            <strong>Extendible :</strong>&nbsp;Partition Walls of the sheds can
            be collapsed to extend their size if required.
          </p>
          <p>
            <strong>iii)</strong>&nbsp;I<strong>ndustrial Gala&nbsp;</strong>
          </p>
          <p>
            These are smaller units ideal for Small and Medium enterprises
            operating on Plug and Play mechanism. The highlights are:
          </p>
          <p>
            <strong>No. of units:&nbsp;</strong>12
            <br />
            <strong>Construction:&nbsp;</strong>Building in RCC mode with G+1
            structure
            <br />
            <strong>Space:</strong>&nbsp;300 sqmtr.( approx 3200 sqft)
            <br />
            <strong>Dimension:</strong>&nbsp;18 mtr * 13 mtr * 3.5 mtr with 4
            mtr wide alley inside the building.
            <br />
            <strong>Loading Unloading:</strong>&nbsp;Bay attached to either end
            of the&nbsp;&nbsp; Gala building
            <br />
            <strong>Lift facility:</strong>&nbsp;Goods Lift at both ends of the
            building connected to the Unloading section
            <br />
            <strong>Water Supply:</strong>&nbsp;Abundant supply, from in-house
            Water reservoir
            <br />
            <strong>Power:</strong>&nbsp;Uninterrupted supply
            <br />
            <strong>ETP/STP:</strong>&nbsp;Facility available for use.
            <br />
            <strong>Utility service:</strong>&nbsp;Toilet blocks for Female and
            Male workers present on both the floors.
          </p>
          <p>
            <strong>C) FACILITIES</strong>
          </p>
          <p>
            i)&nbsp;<strong>Warehouse</strong>
          </p>
          <p>
            The Mega Food Park offers 8000 sqmtr space for storage of which 4000
            sqmtr is used for Pre processing facilities. Thus an entrepreneur
            does not need to make capital investment on the account of storage.
            The warehouse has a height of 6 mtrs.
          </p>
          <p>
            ii)&nbsp;<strong>Cold Store Complex</strong>
          </p>
          <p>
            <strong>a) Potato Cold Storage</strong>
          </p>
          <p>
            The Potato Cold Storage has been built with state-of-the-art systems
            and has the following facilities
          </p>
          <p>
            <strong>Feature 1</strong>: Each Chamber has 3 Circulating Fans
            along with Air plenum. The Air is evenly circulated throughout the
            Chamber
            <br />
            <strong>Benefit:</strong>&nbsp;Causes drying of any
            infected&nbsp;potato&nbsp;and does not allow infection to spread.
            This reduces wastage drastically.
          </p>
          <p>
            <strong>Feature 2:&nbsp;</strong>Each Chamber has Humidifiers which
            maintain RH between 95 to 97% which is the optimal Humidity level
            required for&nbsp;Potato.
            <br />
            <strong>Benefit:&nbsp;</strong>There is little or no change
            in&nbsp;Potato&nbsp;in terms of shrinkage or external attributes.
            Also look and shape of Potato&nbsp;remains intact.
          </p>
          <p>
            <strong>Feature 3:</strong>&nbsp;Temperature is maintained at 11 deg
            C. which is the ideal temperature for&nbsp;potato.
            <br />
            <strong>Benefit:</strong>&nbsp;LOW sugar Potato is preferred for
            Potato Chips manufacturing and is also in demand by discerning
            consumers.
          </p>
          <p>
            Other technical aspects of &nbsp;
            <strong>Potato&nbsp;Cold&nbsp;Storage</strong>&nbsp;are:
          </p>
          <p>Total Capacity: 10,000 MT</p>
          <ul>
            <li>
              <strong>Number of Chambers:</strong>&nbsp;13; of which 12 for bulk
              storage and 1 for Bag storage.
            </li>
            <li>
              <strong>Total size of PCS complex:</strong>&nbsp;76 mtr * 79 mtr *
              7 mtr which contains 13 chambers.
            </li>
            <li>
              <strong>Average size of chamber:</strong>&nbsp;24 mtr* 14 mtr * 6
              mtr
            </li>
            <li>
              <strong>Control Systems:</strong>&nbsp;&nbsp;Omnivent Techniek
              B.V., from Netherlands and Johnsons Controls.
            </li>
            <li>
              <strong>Product Handling:</strong>&nbsp;Specialised equipments
              such as Grader, Space Finder, Scooter and Conveyors are used for
              loading and unloading of products.
            </li>
            <li>
              <strong>Fumigation facility:</strong>&nbsp;Available
            </li>
            <li>
              <strong>Testing Lab:</strong>&nbsp;Available
            </li>
          </ul>
          <p>
            <strong>B) Fruit &amp; Vegetable Cold Storage</strong>
          </p>
          <p>Noteworthy features are:</p>
          <ul>
            <li>
              <strong>Capacity:</strong>&nbsp;5000 MT
            </li>
            <li>
              <strong>Size of F&amp;V complex:</strong>&nbsp;75 mtr * 79 mtr *
              12 mtr.
            </li>
            <li>
              <strong>Temperature:</strong>&nbsp;0 to 4 deg C.
            </li>
            <li>
              <strong>Chambers:</strong>&nbsp;10
            </li>
            <li>
              <strong>Size of chamber:</strong>&nbsp;34 mtr * 12 mtr with a
              height of 7.5 mtr
            </li>
            <li>
              <strong>Panel:</strong>&nbsp;120mm Puff panel used.
            </li>
            <li>
              <strong>Alignment:</strong>&nbsp;Each chamber comprises 4 rows of
              Heavy Duty Storage racks.For easy mobility, aisle space of 3 mtr
              is maintained between the rows.
            </li>
            <li>
              <strong>Material handling:</strong>&nbsp;by Battery and Hydraulic
              operated machines.
            </li>
            <li>
              <strong>Control Systems:</strong>&nbsp;Omnivent Techniek B.V,
              Netherlands and Johnson Control systems used.
            </li>
          </ul>
          <p>
            <strong>C) Deep Freezer including Pre cooling Unit</strong>
          </p>
          <p>Noteworthy features are:</p>
          <ul>
            <li>
              <strong>Capacity:</strong>&nbsp;3000 MT
            </li>
            <li>
              <strong>Temp:</strong>&nbsp;(-) 18 deg C.
            </li>
            <li>
              <strong>No. of Chambers:</strong>&nbsp;6
            </li>
            <li>
              <strong>Size of each chamber:</strong>&nbsp;35 mtr * 7.5mtr * 12
              mtr.
            </li>
            <li>
              <strong>Panel:</strong>&nbsp;IQF facility of capacity 1.5 TPH is
              available.
            </li>
            <li>
              <strong>D)</strong>&nbsp;Other ancillary support ( Show pic
              against each )<strong>Administrative Building:&nbsp;</strong>It is
              a massive and well decorated building that accommodates office
              space for entrepreneurs inside the Mega Food Park. Apart from
              office space, the building has provision for Conference Halls,
              Banks and Post office as well.
            </li>
            <li>
              <strong>Row Houses</strong>: These are well furnished residential
              quarters for key personnel of the manufacturing units in the Mega
              Food Park. There is provision of 21 units of Row houses.
            </li>
            <li>
              <strong>Truck Terminal</strong>&nbsp;: The terminal has provision
              to hold as many as 25 Trucks along with drivers’ amenities.
              <br />
              <strong>Weigh Bridge</strong>: a 60 MT Static pitless Weigh bridge
              is in place.
              <br />
              <strong>Instant Quick Freezing</strong>: The IQF unit has a
              proposed capacity of 1.5 TPH
              <br />
              <strong>Multi crop Pulping</strong>: The unit has a proposed
              capacity of 7425 MT
              <br />
              <strong>Refrigerated transport</strong>: In- house Reefer Van for
              transportation to Siliguri, Kolkata or other hubs as required will
              be available as a part of the Backward/ Forward integration.
              <br />
              <strong>Helipad</strong>: To facilitate quick connectivity with
              nearest airstrips at Kolkata and Bagdogra, the Park has a fully
              equipped Helipad within it.
            </li>
          </ul>
        </BoxContainer>
      </div>
    </div>
  );
}
