import styled from 'styled-components'
import Background from '../../Assets/backgroundImage.jpg'




export const GlobalStyle = styled.div`
    background-image: url(${Background});
    background-position: left top;
    background-size: auto;
    background-repeat: repeat;
    background-attachment: scroll;


`