import styled from "styled-components"


export const Link = styled.a`
    color:#666 !important;
    text-decoration:none !important;
    
`


export const Hr = styled.hr `
  margin:0px;
`



export const Li = styled.li`
    list-style:none;
    padding:15px;
    &:hover{
        background:#f2f2f2;
    }


`    
    
export const Ul = styled.ul`
    padding:0;
    margin:0;

`





export const Container = styled.div`
    position: absolute;
    
    background-color:white;
    text-align: initial;
    z-index:9999999;
    transition: all 0.3s ease-in;
    @media only screen and (max-width: 768px) {
        width:100%
    }

`

