import styled from 'styled-components'


export const Jumbo = styled.div`
    background-color: #222;
    color: #ddd;
    padding: 60px;
    width: 100%;
    font-family:Arial, Helvetica, sans-serif;

`

export const H3 = styled.h3`
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    
    `