import React from "react";

export default function List() {
  return (
    <div>
      <ol>
        <li>
          Road
          <ol>
            <li>
              NH34 Connects:
              <ol>
                <li>Jangipur Kolkata in South</li>
                <li>
                  NH31 Patna – Guwahati at Dalkola near Siliguri in North3
                </li>
                <li>NH34 – Four laning work-in-progress</li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          Railway
          <ol>
            <li>
              Jangipur road station on Kolkata – Guwahati line – 3 Km from
              project site
            </li>
            <li>
              Lalgola station on Kolkata – Lalgola line&nbsp;– 10&nbsp;Km from
              project site
            </li>
            <li>Moregram Station 12 Km from Project site.</li>
          </ol>
        </li>
        <li>
          Air
          <ol>
            <li>Closest airport – Kolkata 250 Kms.</li>
            <li>Bagdogra 300 Kms</li>
          </ol>
        </li>
        <li>
          Helipad
          <ol>
            <li>Fully equipped helipad located within project site</li>
          </ol>
        </li>
      </ol>
    </div>
  );
}
