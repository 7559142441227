import React from "react";
// import {  Img } from "./CareerStyled";
// import { Container } from "reactstrap";
import CareerBanner from "../Assets/career-banner.jpg";
import { BoxContainer, H1 } from "./BaseContentStyled";
import styled from "styled-components";
import { Helmet } from "react-helmet";

const Img = styled.img`
  width: 100%;
  height: auto;
  padding-bottom: 40px;
`;
export default function Career() {
  return (
    <div style={{ backgroundColor: "white" }}>
      <Helmet>
        <title>Careers</title>
      </Helmet>
      <BoxContainer>
        <H1>Careers</H1>
        <Img src={CareerBanner} alt=""></Img>
        <H1>Empower Your Future</H1>
        <p>
          We welcome talented individuals in our team who are eager and inspired
          to contribute to the growth of this Mega Food Park. Drop your CV
          at&nbsp;
          <a href="mailto:info@jangipurmegafoodpark.com">
            info@jangipurmegafoodpark.com
          </a>
          &nbsp;to enhance your careers.
        </p>
      </BoxContainer>
    </div>
  );
}
