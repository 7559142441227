import React from "react";
import { Container } from "react-bootstrap";
import { SFooter } from "./FooterStyled";

export default function Footer() {
  return (
    <SFooter>
      <Container>COPYRIGHT © 2020 JANGIPUR BENGAL MEGA FOOD PARK</Container>
    </SFooter>
  );
}
