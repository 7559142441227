import React from "react";
import { H1, BoxContainer } from "../../BaseContentStyled";
import { Helmet } from "react-helmet";
// import { Container  } from "reactstrap";

export const What = () => {
  return (
    <div style={{ backgroundColor: "white" }}>
      <Helmet>
        <title>What</title>
      </Helmet>
      <BoxContainer>
        <H1>What</H1>
        <p>
          A Food Park is a sector-specific industrial park where Developed Land
          and common facilities such as Internal roads. Power, Water supply are
          provided to entrepreneurs for setting up manufacturing units in food
          processing. This is a step ahead from the stage where the entrepreneur
          needs to procure the Land and arrange for all additional
          infrastructure on its own causing huge expenditure of time and added
          hassles.
        </p>
        <p>
          The Mega Food Park scheme took this even a step further. Under this
          scheme launched in 2008, the Park would provide common facilities such
          as water, electricity and effluent treatment apart from specialized
          facilities like cold storage, warehousing, logistics, backward and
          forward integration through the network of Primary Processing Centres
          (PPC) and Collection Centres (CC) connecting the Central Processing
          Centre (CPC). Thus Mega Food Park is an includive concept that aims to
          establish direct linkages from Farm to Processing to Consumer Markets.
          This structure is based on a Hub and Spoke model. The success of a
          Mega Food Park is hinged on the efficient logistics that link the CCs
          to PPCs to CPC.
        </p>
        <p>
          The Government provides Financial assistance upto Rs 50 Crore to set
          up modern infrastructure facilities for Food Processing at Mega Food
          Parks.
        </p>
        <p>
          The project is executed through a Special Purpose Vehicle (SPV). It is
          a Company registered under the Companies Act and is required to have
          at least 3 entrepreneurs or business units independent of each other
          with no common directors.
        </p>
      </BoxContainer>
    </div>
  );
};
export default What;
