import styled from 'styled-components'
import { Link } from "react-router-dom";

export const SCard = styled.div`
    display: flex;
    justify-content: center;
    
`

export const Img = styled.img`
    height:auto;
    width:100% ;
    max-width: 300px;
    max-height:200px;
    margin-bottom:12px;
`

export const H4 = styled.h4`
    margin-left:10px;
    font-size: 1.8rem;
    line-height: 1;
    margin: 20px 0 ;
    color: #333;
    text-align:center
    

`
export  const A = styled(Link)`
    font-size:18px;
    font-weight:700;
    color:black;
    text-decoration:none !important;
    &:hover{
        color:  #64c9ea;
    }

`