import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import Card from "./Components/Card";
import AboutUS from "../../Assets/Home_Assets/About-us-300x200.jpg";
import WhyJagnipur from "../../Assets/Home_Assets/Why-Jagnipur.jpg";
import WhatWeOffer from "../../Assets/Home_Assets/What-we-offer.jpg";
import { Jumbo, H3 } from "./HomeStyled";
import { Helmet } from "react-helmet";

export default function Home() {
  return (
    <div>
      <Helmet>
        <title>Jangipur Mega Food Park | Home</title>
      </Helmet>
      <Container style={{ backgroundColor: "white" }}>
        <div style={{ padding: "60px 30px 0" }}>
          <Row>
            <Col lg={4} md={4}>
              <Card icon={AboutUS} title={"About Us"} link={"/about-us"}>
                CONCEPTS A Food Park is a sector-specific industrial park where
                Developed Land and common facilities such as Internal roads.
                Power, Water supply are provided to entrepreneurs for setting up
                …
              </Card>
            </Col>
            <Col lg={4} md={4}>
              <Card
                icon={WhyJagnipur}
                title={"Why Jangipur"}
                link={"/why-jangipur"}
              >
                Why Jangipur? Jangipur in Murshidabad district is the chosen
                location for the Mega Food Park in West Bengal. The reasons are:
                EASY CONNECTIVITY- RoadNH34 Connects:Jangipur Kolkata in …
              </Card>
            </Col>
            <Col lg={4} md={4}>
              <Card
                icon={WhatWeOffer}
                title={" What We Offer"}
                link={"/what-we-offer"}
              >
                Jangipur Mega Food Park occupies an expanse of nearly 90 Acres
                in the First phase with potential to expand upto 250 Acres in
                2nd and 3rd phases. A. Infrastructure i) Road: Wide …
              </Card>
            </Col>
          </Row>
        </div>
      </Container>
      <Jumbo>
        <section id="custom_html-3">
          <div>
            <div>
              <H3>
                <span>
                  Jangipur is the largest hub for Processed food in West Bengal.{" "}
                </span>
              </H3>
              <br />
              The Jangipur Mega Food Park has been conceptualized for the Food
              Processing industry keeping in mind the comparative advantage,
              West Bengal enjoys in this field.
            </div>
          </div>
        </section>
      </Jumbo>
    </div>
  );
}
