import styled from 'styled-components'

export const Logo = styled.img`
    max-width:160px;
    min-height:100px;

`

export const WelcomeBanner = styled.img`
    
    width:80%;
    height:auto;
    padding: 28px 0 0;
    /* margin-left:12px */



`