import React, { Component } from "react";
import { LinkContainer } from "react-router-bootstrap";
import { Container, Nav } from "react-bootstrap";
import Router from "./Router";
import { NavLink, Navs } from "./BaseContentStyled";
import Dropdown from "./ContentComponents/Dropdown/Dropdown";
import TabDropdown from "./ContentComponents/TabDropdown/TabDropdown";



export class BaseContent extends Component {
  state = {
    expand: false,
  };

  render() {
    return (
      <div>
        <Container>
          <TabDropdown />
          <hr style={{margin:"0"}}/>
          <Navs
            justify
            variant="pills"
            defaultActiveKey="/"
            style={{ backgroundColor: "#f2f2f2" }}
          >
            <Nav.Item>
              <LinkContainer to="/" exact>
                <NavLink eventKey="link-1">Home</NavLink>
              </LinkContainer>
            </Nav.Item>

            <Nav.Item
              onMouseEnter={() => this.setState({ expand: true })}
              onMouseLeave={() => this.setState({ expand: false })}
            >
              <LinkContainer to="/mega-food-park">
                <NavLink eventKey="link-2">Mega Food Park </NavLink>
              </LinkContainer>
              {this.state.expand ? <Dropdown /> : null}
            </Nav.Item>

            <Nav.Item>
              <LinkContainer to="/why-jangipur">
                <NavLink eventKey="link-3">Why Jangipur</NavLink>
              </LinkContainer>
            </Nav.Item>

            <Nav.Item>
              <LinkContainer to="/what-we-offer">
                <NavLink eventKey="link-4">What We Offer</NavLink>
              </LinkContainer>
            </Nav.Item>

            <Nav.Item>
              <LinkContainer to="/other-links">
                <NavLink eventKey="link-5">Other links</NavLink>
              </LinkContainer>
            </Nav.Item>

            <Nav.Item>
              <LinkContainer to="/contact-us">
                <NavLink eventKey="link-6">Contact Us</NavLink>
              </LinkContainer>
            </Nav.Item>

            <Nav.Item>
              <LinkContainer to="/careers">
                <NavLink eventKey="link-7">Careers</NavLink>
              </LinkContainer>
            </Nav.Item>
          </Navs>

          <Router />
        </Container>
      </div>
    );
  }
}

export default BaseContent;
