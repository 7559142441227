import React, { Component } from "react";
import { LinkContainer } from "react-router-bootstrap";
import { Nav, NavDropdown } from "react-bootstrap";
import Dropdown from "../Dropdown/Dropdown";
import styled from "styled-components";

const NDropdown = styled(NavDropdown)`
    display:none;
    @media only screen and (max-width: 768px) {
        
        display:grid;
    }
  /* color: red; */
  color: black;
  .dropdown-menu {
    width: 100%;
    background-color: #f2f2f2;
    color: black;
  }
  .nav-link {
    color: #666;
  }
`;

const Button = styled.button`
  float: right;
  border: none;
  outline: none;
  margin-top:10px;
  margin-right:10px;
`


class TabDropdown extends Component {
  state = {
    collapsed: false,
  };
  render() {
    return (
      <div
        style={{ backgroundColor: "white", textAlign: "center", width: "100%" ,borderColor:"gray"}}
      >
        <NDropdown title="Menu">
          <Nav.Item>
            <LinkContainer to="/" exact>
              <Nav.Link eventKey="link-1">Home</Nav.Link>
            </LinkContainer>
          </Nav.Item>
          <hr/>
          <Nav.Item>
            <Button
              className={
                this.state.collapsed ? "fas fa-angle-up" : "fas fa-angle-down"
              }
              onClick={() => {
                this.setState({ collapsed: !this.state.collapsed });
              }}
            />
            <Nav.Link href="/mega-food-park" eventKey="link-2">
              Mega Food Park
            </Nav.Link>
          </Nav.Item>
          {this.state.collapsed ? <Dropdown  /> : null}
          <hr/>
          <Nav.Item>
            <LinkContainer to="/why-jangipur">
              <Nav.Link eventKey="link-3">Why Jangipur</Nav.Link>
            </LinkContainer>
          </Nav.Item>
          <hr/>
          <Nav.Item>
            <LinkContainer to="/what-we-offer">
              <Nav.Link eventKey="link-4">What We Offer</Nav.Link>
            </LinkContainer>
          </Nav.Item>
          <hr/>
          <Nav.Item>
            <LinkContainer to="/other-links">
              <Nav.Link eventKey="link-5">Other links</Nav.Link>
            </LinkContainer>
          </Nav.Item>
          <hr/>
          <Nav.Item>
            <LinkContainer to="/contact-us">
              <Nav.Link eventKey="link-6">Contact Us</Nav.Link>
            </LinkContainer>
          </Nav.Item>
          <hr/>
          <Nav.Item>
            <LinkContainer to="/careers">
              <Nav.Link eventKey="link-7">Careers</Nav.Link>
            </LinkContainer>
          </Nav.Item>
        </NDropdown>
      </div>
    );
  }
}
export default TabDropdown;
