import React from "react";
import murshidabad_road from "../../Assets/murshidabad_road.jpg";
import {  H1,BoxContainer } from "../BaseContentStyled";
import List from "./Components/List";
import styled from 'styled-components'
import { Helmet } from "react-helmet";



const Img =  styled.img`
    max-width:100%;
    height:auto;
    padding:20px 0 30px 0;


`

export default function WhyJangipur() {
  return (
    <div style={{ backgroundColor: "white" }}>
      <Helmet>
        <title>Why Jangipur</title>
      </Helmet>
      <BoxContainer >
        <H1>Why Jangipur</H1>
        <Img src={murshidabad_road}></Img>
        <p>
          <strong>Why Jangipur?</strong>
        </p>
        <p>
          Jangipur in Murshidabad district is the chosen location for the Mega
          Food Park in West Bengal. The reasons are:
        </p>
        <p>
          <strong>EASY CONNECTIVITY-</strong>
        </p>
        <List />
        <p>
          <strong>Backward and Forward Linkage:</strong>&nbsp;The Park operates
          on the Hub and Spoke model. Consistent supply of Raw material is
          assured for an optimal period during the year through a network of 6
          Primary Processing Centres Forward Linkage is assured to markets in
          the country and abroad.
        </p>
        <p>
          <strong>Availability of resources:</strong>
        </p>
        <p>
          High quality Raw materials in terms of Fruits, Vegetables, Potato,
          Rice, Flour , Dairy, Grains etc can be sourced from the Zone of
          Influence covering the neighbouring 4 districts, i.e. Malda,
          Bardhaman, Birbhum, Nadia and Murshidabad.. This Zone of Influence
          offers production of Fresh Produce to the tune of:
        </p>
        <p>Vegetables:5.09 Million MT</p>
        <p>Fruits: 1.42 Million MT</p>
        <p>
          <strong>Potential Target markets:</strong>&nbsp;The Food Park is
          ideally located to service markets in East and North east India,
          Bihar, Jharkhand as well as export markets of Bangladesh, Nepal,
          Bhutan.
        </p>
      </BoxContainer>
    </div>
  );
}
