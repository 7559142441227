import React from "react";
import { BoxContainer, H1 } from "../../BaseContentStyled";
import {Helmet} from 'react-helmet'

export const Why = () => {
  return (
    <div style={{ backgroundColor: "white" }}>
      <Helmet>
        <title>Why</title>
      </Helmet>
      <BoxContainer>
        <H1>Why</H1>
        <p>The key objectives of the scheme are</p>
        <ul>
          <li>To reduce wastage of perishables</li>
          <li>Raise processing of food items from 6% to 20%</li>
          <li>Increase india’s share in Food processing from 1.5% to 3%</li>
        </ul>
        <p>
          <strong>The Mega Food Park Project: then and now</strong>
        </p>
        <p>
          When the scheme was launched in 2008, 42 Mega Food Parks were to be
          established by 2015. However allotment to 17 players had got cancelled
          due to various reasons. In March 2015, these 17 parks were allocated
          to new players including state governments and private firms.
          Currently only 6 Mega Food Parks are operational in India including
          JANGIPUR MEGA FOOD PARK in West Bengal.
        </p>
      </BoxContainer>
    </div>
  );
};

export default Why;
