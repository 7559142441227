import styled from "styled-components";


export const SFooter = styled.div`
    font-size:12px !important;
    color: #222;
    font-size: 12px;
    font-size: 1.2rem;
    padding: 40px 0;
    text-align: center;
    text-transform: uppercase;
    line-height: 1.6;


`