import React from "react";
import { Container } from "reactstrap";
import styled from "styled-components";
import { H1 } from "./BaseContentStyled";
import { Helmet } from "react-helmet";

const A = styled.a`
  width: 100%;
`;

export default function OtherLinks() {
  return (
    <div>
      <Helmet>
        <title>Other Links</title>
      </Helmet>
      <div style={{ backgroundColor: "white" }}>
        <Container style={{ padding: "40px 60px 60px" }}>
          <H1>Other Links</H1>
          <ol>
            <li>
              <strong>
                Incentive Scheme for MSMEs offered by Govt. of West
                Bengal:&nbsp;
              </strong>
              <div style={{ overflow: "auto" }}>
                <A href="http://foodprocessingindia.co.in/state_pdf/WestBengal/wb_msme_policy_2013-18.pdf">
                  <strong>
                    http://foodprocessingindia.co.in/state_pdf/WestBengal/wb_msme_policy_2013-18.pdf
                  </strong>
                </A>
              </div>
            </li>
            <li>
              <strong>
                Incentive Schemes for Macro industries by WBIDC&nbsp;
              </strong>
              <br />
              <div style={{ overflow: "auto" }}>
                <a href="http://www.wbidc.com/images/pdf/WBSSIS_Amended_full_book_12092014_Notified.pdf">
                  <strong>
                    http://www.wbidc.com/images/pdf/WBSSIS_Amended_full_book_12092014_Notified.pdf
                  </strong>
                </a>
              </div>
            </li>
            <li>
              <strong>Loan facility offered by NABARD.</strong>
              <br />
              <strong>https://www.nabard.org/foodprocessing.pdf</strong>
            </li>
            <li style={{ overflow: "auto" }}>
              <strong>
                Ministry of Food Processing Industries, Govt. of India.
              </strong>
              <br />
              http://mofpi.nic.in/SchemeViewPage.aspx?OPsNrN8PJA8jfusrKLnLpes8+SZBdvJsEMk2WECzAWEcfiiIgDzknQ
            </li>
            <li>
              <strong>
                Walkthrough Film on Mega Food Park for a detailed overview of
                the Food Park.
                <br />
              </strong>
              https://www.youtube.com/watch?v=VSjXPjmR9lQ
            </li>
          </ol>
        </Container>
      </div>
    </div>
  );
}
