import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import { Logo ,WelcomeBanner} from "./HeaderStyled";
import LogoImg from '../../Assets/Logo.png'
import Welcome from '../../Assets/WelcomeBanner.png'


export default function Header() {
  return (
    <div>
      <Container>
        <Row>
          <Col lg={{span:2,offset:0}} md = {{span :2, offset:5}} sm = {{span :3, offset:4}} xs = {{span :3, offset:3}}>
            <Logo src ={LogoImg} alt=""/>
          </Col>
          <Col lg ={{span:7,offset:1}} md ={{span:12,offset:1}}  sm={{span:12,offset:1}} xs={{span:12,offset:1}}>
                <WelcomeBanner src ={Welcome}/>
          </Col>
          <br/>
        </Row>
      </Container>
    </div>
  );
}
