import React from "react";
// import { Container } from "react-bootstrap";
import styled from "styled-components";
import Team from "../../../Assets/Mgmnt-Team.png";
import { BoxContainer, H1 } from "../../BaseContentStyled";
import { Helmet } from "react-helmet";

export const Img = styled.img`
  width: 100%;
  height: auto;
  padding-bottom: 40px;
`;

const Management = () => {
  return (
    <div style={{ backgroundColor: "white" }}>
      <Helmet>
        <title>Management Team</title>
      </Helmet>
      <BoxContainer>
        <H1>Management Team</H1>
        <Img src={Team} alt=""></Img>
        <ol>
          <li>
            <strong>Promoters</strong>
            <ul>
              <li>ABUL KALAM &amp; SONS INDUSTRIES PVT. LTD</li>
              <li>IFCI INFRASTRUCTURE DEVELOPMENT LIMITED</li>
              <li>SHIV BIRI MANUFACTURING PVT LTD</li>
              <li>
                <p id="12" data-gr-id="12">
                  DELIKA
                </p>{" "}
                FOODS PVT LTD
              </li>
              <li>
                WEST BENGAL STATE FOOD PROCESSING &amp; HORTICULTURE DEVELOPMENT
                CORPORATION LIMITED
              </li>
              <li>INTERNATIONAL PRINT-O-PAC LIMITED</li>
              <li>IFCI VENTURE CAPITAL FUNDS LTD.</li>
            </ul>
          </li>
          <li>
            <strong>List of Directors</strong>
            <ul>
              <li>Mr Abul Kalam</li>
              <li>Mr Ziauddin Biswas</li>
              <li>Mr Ajijur Rahaman</li>
              <li>Mr Azharuddin Biswas</li>
              <li>Mr Jakir Hossain</li>
              <li>Mr Samik Dasgupta</li>
              <li>Mr Ranjit Choudhury</li>
              <li>Mr Santanu Ghosh</li>
              <li>Mr Bikash Kanti Roy</li>
            </ul>
          </li>
        </ol>
      </BoxContainer>
    </div>
  );
};

export default Management;
