import React from "react";
import { BoxContainer, H1 } from "./BaseContentStyled";
import { Helmet } from "react-helmet";

const ContactUs = () => (
  <div style={{ backgroundColor: "white" }}>
    <Helmet>
      <title>Contact Us</title>
    </Helmet>
    <BoxContainer>
      <H1>Contact Us</H1>
      <H1>Corporate Office</H1>
      <p>
        88, Park Street.Near Park Circus 7 Point Crossing4th floorKolkata – 17
      </p>
      <p>
        <strong>Site</strong>
        <br />
        Vill Kulori, PO. Gankar Raghunathganj,
        <br />
        Dist. Murshidabad,
        <br />
        West Bengal 742227
      </p>
      <p>
        <strong>Telefax: +91-033-228-20148</strong>
      </p>
      <p>
        <strong>Email:&nbsp;</strong>
        <a href="mailto:info@jangipurmegafoodpark.com">
          <strong>info@jangipurmegafoodpark.com</strong>
        </a>
      </p>
    </BoxContainer>
  </div>
);

export default ContactUs;
