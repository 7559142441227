import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./Home/Home";
// import { Container } from 'react-bootstrap';
import MegaFoodPark from "./MegaFoodPark";
import WhyJangipur from "./WhyJangipur/WhyJangipur";
import WhatWeOffer from "./WhatWeOffer";
import OtherLinks from "./OtherLinks";
import ContactUs from "./ContactUs";
import Career from "./Career";
import What from "./ContentComponents/WhatComponent/What";
import Why from "./ContentComponents/WhyComponent/Why";
import ManagementTeam from "./ContentComponents/ManagementTeamComponent/Management";
import AboutUs from "./ContentComponents/AboutUs";





export default function Routes() {
  return (
    <div>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/mega-food-park" component={MegaFoodPark} />
        <Route path="/why-jangipur" component={WhyJangipur} />
        <Route path="/what-we-offer" component={WhatWeOffer} />
        <Route path="/other-links" component={OtherLinks} />
        <Route path="/contact-us" component={ContactUs} />
        <Route path="/careers" component={Career} />
        <Route path="/what" component={What} />
        <Route path="/why" component={Why} />
        <Route path="/management_team" component={ManagementTeam} />
        <Route path="/about-us" component={AboutUs} />
      </Switch>
    </div>
  );
}
