import React from "react";
import { Li, Link, Container, Ul, Hr } from "./DropdownStyled";
// import { LinkContainer } from "react-router-bootstrap";

const Dropdown = () => {
  return (
    <Container>
      <Ul>
        <Link href="/what">
          <Li>What</Li>
        </Link>
        <Hr />
        <Link href="/why">
          <Li >Why</Li>
        </Link>
        <Hr />
        <Link href="/management_team">
          <Li>Management Team</Li>
        </Link>
      </Ul>
    </Container>
  );
};

export default Dropdown;
