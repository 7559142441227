import React from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import BaseContent from "../../Content/BaseContent";
import { GlobalStyle } from "./BaseLayoutStyled";
import { BrowserRouter as Router } from "react-router-dom";

export default function BaseLayout() {
  return (
    <Router>
      <GlobalStyle>
        <Header />
        <BaseContent />
        <Footer />
      </GlobalStyle>
    </Router>
  );
}
