import React from "react";
// import { Container } from "reactstrap";
import { H1, BoxContainer } from "./BaseContentStyled";
import MegaFoodParkImg from "../Assets/MegafoodParkImg.png";
import styled from "styled-components";
import { Helmet } from "react-helmet";

const Img = styled.img`
  width: 100%;
  height: auto;
  padding-bottom: 40px;
`;

const MegaFoodPark = () => {
  return (
    <div style={{ backgroundColor: "white" }}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Mega Food Park</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <BoxContainer>
        <H1>Mega Food Park</H1>
        <Img src={MegaFoodParkImg} alt=""></Img>
        <p>
          <strong>CONCEPTS</strong>
        </p>
        <p>
          A Food Park is a sector-specific industrial park where Developed Land
          and common facilities such as Internal roads. Power, Water supply are
          provided to entrepreneurs for setting up manufacturing units in food
          processing. This is a step ahead from the stage where the entrepreneur
          needs to procure the Land and arrange for all infrastructure on his
          own incurring huge expenditure of Time, Money and logistic
          difficulties.
        </p>
        <p>
          The Mega Food Park scheme took this even a step further. Under this
          scheme launched in 2008, the Park would provide common facilities such
          as water, electricity and effluent treatment apart from specialized
          facilities like cold storage, warehousing, logistics, backward and
          forward integration through the network of Primary Processing Centres
          (PPC) and Collection Centres (CC) connecting the Central Processing
          Centre (CPC). Thus Mega Food Park is an inclusive concept that aims to
          establish direct linkages from Farm to Processing to Consumer Markets.
          This structure is based on a Hub and Spoke model. The success of a
          Mega Food Park is hinged on the efficient logistics that link the CCs
          to PPCs to CPC.
        </p>
        <p>
          The Government provides Financial assistance upto Rs 50 Crore to set
          up modern infrastructure facilities for Food Processing at Mega Food
          Parks.
        </p>
        <p>
          The project is executed through a Special Purpose Vehicle (SPV). It is
          a Company registered under the Companies Act and is required to have
          at least 3 entrepreneurs or business units independent of each other
          with no common directors.
        </p>
        <p>
          <strong>OBJECTIVES</strong>
        </p>
        <ul>
          <li>To reduce wastage of perishables</li>
          <li>Raise processing of food items from 6% to 20%</li>
          <li>Increase india’s share in Food processing from 1.5% to 3%</li>
        </ul>
        <p>
          <strong>The Mega Food Park Project: then and now</strong>
        </p>
        <p>
          When the scheme was launched in 2008, 42 Mega Food Parks were to be
          established by 2015. However allotment to 17 players had got cancelled
          due to various reasons. In March 2015, these 17 parks were allocated
          to new players including state governments and private firms.
          Currently only 6 Mega Food Parks are operational in India including
          JANGIPUR MEGA FOOD PARK in West Bengal.
        </p>
        <p>
          <strong>Availability of resources:&nbsp;</strong>High quality Raw
          materials in terms of Fruits, Vegetables, Potato, Rice, Flour , Dairy,
          Grains etc can be sourced from the Zone of Influence covering the
          neighbouring 4 districts, i.e. Malda, Bardhaman, Birbhum, Nadia and
          Murshidabad.. This Zone of Influence offers production of Fresh
          Produce to the tune of:
        </p>
        <p>Vegetables:5.09 Million MT</p>
        <p>Fruits: 1.42 Million MT</p>
        <p>
          <strong>Potential Target markets:</strong>&nbsp;The Food Park is
          ideally located to service markets in East and North east India,
          Bihar, Jharkhand as well as export markets of Bangladesh, Nepal,
          Bhutan.
        </p>
      </BoxContainer>
    </div>
  );
};

export default MegaFoodPark;
