import styled from 'styled-components'
import { Nav, NavDropdown ,Container} from 'react-bootstrap'




export const Navs = styled(Nav)`
    @media only screen and (max-width: 768px) {
        
        display:none;
    }

    &.nav-pills .nav-link{
        border-radius:0;
    }



`

export const NDropdown = styled(NavDropdown)`
    display:none;
    @media only screen and (min-width: 768px) {
        display:flex
    }


`

export const NavLink = styled(Nav.Link)`
    transition: all 0.3s ease-in;
    color: #666;
    &.nav-link.active{
        background-color: #64c9ea;
    }
    &:hover{
        color: #666;
        background-color:white;
    }
    


`

export const H1 = styled.h1`
    font-weight:bold;
    line-height: 1;
    margin-bottom: 20px;
    font-size: 42px;
    @media only screen and (max-width: 768px) {
    font-size: 24px;
  }

`

export const BoxContainer =styled(Container)`
    padding: 40px 60px 60px ;
    @media only screen and (max-width: 768px) {
        padding: 40px 20px 60px ;
  }


`

