import React from 'react';
import './App.css';
import BaseLayout from './Layout/BaseLayout/BaseLayout'


function App() {
  return (
    <div className="App">
        <BaseLayout/>
    </div>
  );
}

export default App;
