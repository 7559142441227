import React from "react";
import { SCard, Img, H4, A } from "./CardStyled";
import { Link } from "react-router-dom";


export default function Card(props) {
  return (
    <div style={{ marginBottom: "4em" }}>
      <SCard>
        <Img src={props.icon} alt="" />
      </SCard>
      <H4>
        <A to={props.link}>
          {props.title}
        </A>
      </H4>
      <div style={{ marginBottom: "16px", marginLeft: "10px" }}>
        <p>{props.children}</p>
        <div style={{ marginTop: "30px" }}>
          <Link
            style={{ color: "#64c9ea", marginTop: "30px !important" }}
            to={props.link}
          >
            Read more
          </Link>
        </div>
      </div>
    </div>
  );
}
